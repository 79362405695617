<template>
  <div>
    <div class="bg_center"></div>
    <headbox title="理赔说明" bgColor="#fff" :customPath="true" path="claimedit"  :query="{active:'b'}" isborder="true"></headbox>
    <div class="centerbox">
      <div class="step_1">
        <h1>【保险责任范围】</h1>
        <p>
          本保险分为邮包险和邮包一切险两种。被保险货物遭受损失时，本保险按保险单上订明承保险标的条款规定负赔偿责任。
        </p>
        <h2>（一）邮包险</h2>
        <p>本保险负责赔偿：</p>
        <p>
          1．被保险邮包在运输途中由于恶劣气候、雷电、海啸、地震、洪水自然灾害或由于运输工具遭受搁浅、触礁、沉没、碰撞、倾覆、出轨、坠落、失踪或由于失火、爆炸意外事故所造成的全部或部分损失。
        </p>
        <p>
          2．被保险人对遭受承保责任内危险的货物采取抢救、防止或减少货损的措施而支付的合理费用，但以不超过该批被救货物的保险金额为限。
        </p>
        <h2>（二）邮包一切险</h2>
        <p>本保险负责赔偿：</p>
        <p>
          除包括上述邮包险的各项责任外，本保险还负责被保险邮包在运输途中由于外来原因所致的全部或部分损失。
        </p>
      </div>
      <div class="step_2">
        <h1>【理赔流程】</h1>
        <p>第一步：电话报案</p>
        <p>拨打0755-22692109联系保险公司或拨打{{GLOBAL}}客服18688828557寻求协助</p>
        <p>第二步：审核材料</p>
        <p>按照保险公司要求，准备理赔材料，提交保险公司审核</p>
        <p>第三步：赔付完成</p>
        <p>保险公司材料核实通过后，发放赔款</p>
      </div>
      <!-- 申请理赔 -->
      <!-- <div class="Application"><router-link :to="{ name: 'claimedit'}">申请理赔</router-link></div> -->
    </div>
  </div>
</template>
<script>
import headbox from "@/components/head.vue";
export default {
  data() {
    return {};
  },
  methods: {},
  components: {
    headbox
  }
};
</script>
<style lang="scss" scoped="true">
.Application {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #ffb606;
  line-height: 90px;
  color: #fff;
  text-align: center;
  font-size: 34px;
}
.step_1 {
  background: #fff;
  padding: 30px;
  font-size: 28px;
  margin-bottom: 20px;
  h1 {
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 10px;
    line-height: 42px;
  }
}
.step_2 {
  background: #fff;
  padding: 30px;
  font-size: 28px;
  margin-bottom: 20px;
  padding-bottom: 100px;
  h1 {
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 10px;
  }
}
.centerbox {
  position: relative;
  z-index: 3;
}
.bg_center {
  background: #f5f5f5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
</style>
